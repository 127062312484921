// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-item-js": () => import("/opt/build/repo/src/templates/item.js" /* webpackChunkName: "component---src-templates-item-js" */),
  "component---src-templates-tip-js": () => import("/opt/build/repo/src/templates/tip.js" /* webpackChunkName: "component---src-templates-tip-js" */),
  "component---src-templates-column-js": () => import("/opt/build/repo/src/templates/column.js" /* webpackChunkName: "component---src-templates-column-js" */),
  "component---src-templates-news-js": () => import("/opt/build/repo/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-category-js": () => import("/opt/build/repo/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("/opt/build/repo/src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tips-js": () => import("/opt/build/repo/src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

